import _ from 'lodash'
import { action, computed, makeObservable, observable, toJS } from 'mobx'
import { isSafe, safeObject, scrollTo, notifyError, validRequired } from 'uikit'
import FactoryProvider from 'providers/factoryProvider'
import { prepareErrorMessage } from 'utils/error'
import deepMerge from 'deepmerge'
import FactoryModel from 'models/factoryModel'
import { sentryGetMissProductsPickupOffers, sentryGetUnselectedPickupOffers } from 'providers/helpers/pickup'
import BaseModel from '../baseModel'

export const SERVICE_LIFE = 'life_insurance'
export const SERVICE_EMPLOYMENT = 'job_insurance'
export const SERVICE_SMS_BASIC = 'sms-notification-basic'
export const SERVICE_SMS_PLUS = 'sms-notification-plus'

class PickupModel extends BaseModel {
  constructor() {
    super()

    this.finishCalculateOffers = _.debounce(this.finishCalculateOffers, 350)
    makeObservable(this)
  }

  ctx = null

  GLOBAL_ACCESS = 'globalAccess'
  QUESTIONARY_ACCESS = 'questionaryAccess'

  MIN_TERM = 1
  MAX_TERM = 60
  MIN_INITIAL_PAYMENT = 0
  CALCULATION_TIMEOUT = 30 // sec.

  @observable isPreview = true
  @observable cost = ''
  @observable isInitOffers = false

  @observable ownerChangeAccess = new Set()

  calculationId = null
  calculationDate = null
  subId = null

  @observable pointId = null
  @observable pointList = []
  @observable isLoadingPointList = false

  @observable userId = null
  @observable userList = []
  @observable isLoadingUserList = false

  @observable callCenterInfo = ''
  @observable isLoadingCallCenterInfo = false

  @observable isAvailDiscount = false
  @observable term = 12
  @observable initialPayment = 0

  @observable services = []
  @observable isLoadingServices = false

  @observable originalOffers = []
  @observable offers = []
  @observable isLoadingOffers = false
  @observable sortOfferBy = ''
  @observable sortOrder = ''
  @observable sortPressCount = 0
  @observable isShowMonthly = true
  @observable isShowTipsSum = false

  @observable isShowOfferDetails = false
  @observable currentOfferDetails = {}
  @observable currentOfferDetailsIndex = -1
  @observable detailsOriginalOffers = []
  @observable detailsOffers = []
  @observable detailsSortOfferBy = ''
  @observable detailsSortPressCount = 0
  @observable detailsSortOrder = ''
  @observable detailsIsShowMonthly = true
  @observable detailsIsShowTipsSum = true

  @observable isForceValidate = false
  @observable isLoading = false
  @observable isProcessSave = false

  @observable isGetOffersAfterWait = false // TODO Флаг о том, что запрашивались офферы руками по http
  postSentryOffers = [] // TODO Офферы для сентри в случае пустых офферов и офферов с ошибками после окончания расчёта

  clear = () => {
    this.clearOffers()

    this.action(() => {
      this.isPreview = true
      this.cost = ''
      this.isInitOffers = false

      this.pointId = null
      this.pointList = []
      this.isLoadingPointList = false

      this.userId = null
      this.userList = []
      this.isLoadingUserList = false

      this.callCenterInfo = ''
      this.isLoadingCallCenterInfo = false

      this.isLoadingCategoryList = false

      this.isAvailDiscount = false
      this.term = 12
      this.initialPayment = 0

      this.services = []
      this.isLoadingServices = false

      this.isLoading = false
      this.isProcessSave = false

      this.isGetOffersAfterWait = false
      this.postSentryOffers = []
    })
  }

  clearOffers = action(() => {
    this.clearSubscription()

    this.calculationId = null
    this.calculationDate = null
    this.subId = null

    this.originalOffers = []
    this.offers = []
    this.isLoadingOffers = false
    this.sortOfferBy = ''
    this.sortOrder = ''
    this.sortPressCount = 0
    this.isShowTipsSum = false
    this.isShowMonthly = true

    this.isShowOfferDetails = false
    this.currentOfferDetails = {}
    this.currentOfferDetailsIndex = -1
    this.detailsOffers = []
    this.detailsOriginalOffers = []
    this.detailsSortOfferBy = ''
    this.detailsSortOrder = ''
    this.detailsSortPressCount = 0
    this.detailsIsShowTipsSum = true
    this.detailsIsShowMonthly = true
  })

  recalculateRemotelyCalculatedServicePrices = action(() => {
    _.each(this.services, (group) => {
      const remoteCalcServices = _.filter(group.services, service => service.isRemoteCalc && service.isSelect)
      _.each(remoteCalcServices, async (service) => {
        this.action(() => { service.isLoading = true })
        const cost = await this.calculateServiceCost(service.id)
        this.action(() => { service.isLoading = false })
        this.action(() => { service.price = cost })
      })
    })
  })

  @computed get validPoint() {
    if (!this.isOwnerChange) return null
    if (validRequired(this.pointId)) return validRequired(this.pointId)
    return null
  }

  @computed get validUser() {
    if (!this.isOwnerChange) return null
    if (validRequired(this.userId)) return validRequired(this.userId)
    return null
  }

  @computed get validTerm() {
    if (validRequired(this.term)) return 'Не указан'
    if (this.term < this.MIN_TERM) return `Меньше ${this.MIN_TERM}`
    if (this.term > this.MAX_TERM) return `Больше ${this.MAX_TERM}`

    return null
  }

  @computed get validInitialPayment() {
    if (validRequired(this.initialPayment)) return 'Не указан'
    if (Number(this.initialPayment < this.MIN_INITIAL_PAYMENT)) return `Меньше ${this.MIN_INITIAL_PAYMENT}`

    return null
  }

  @computed get validCost() {
    if (!this.isPreview) return null
    if (validRequired(this.cost)) return 'Не указана'
    if (Number(this.cost) < 0) return 'Меньше 0'
    if (Number(this.cost) === 0) return 'Равна 0'
    return null
  }

  @computed get serviceGroupMessages() {
    if (this.isLoadingServices) return []
    return _.filter(this.services, service => service.groupInfo).map(service => service.groupInfo)
  }

  @computed get hasServiceGroupMessages() {
    return !_.isEmpty(this.serviceGroupMessages)
  }

  @computed get isProcessGetOffers() {
    return _.reduce(this.offers, (s, r) => s + (!r.isProcessGetOffers ? 1 : 0), 0) !== this.offers.length
  }

  @computed get isProcessGetTips() {
    if (!FactoryModel.SettingsModel.CommonModel.calculator.showTips) return false
    return !_.every(this.offers, (offer) => _.every(offer.offers, (detailedOffer) => detailedOffer.tipsLoaded))
  }

  @computed get isFinallyCalculated() {
    // завершен расчет и посчитаны АВ
    return !this.isProcessGetOffers && !this.isProcessGetTips
  }

  @computed get isCalculate() {
    return (
      (this.isOwnerChange ? !this.validPoint && !this.validUser : true) &&
        !this.validTerm && !this.validInitialPayment && !this.validCost
    ) && !this.isProcessGetOffers
  }

  @computed get isExistOffers() {
    return !_.isEmpty(this.offers)
  }

  @computed get isNext() {
    return !_.isEmpty(this.offers) && !this.isProcessGetOffers &&
      _.flow(
        c => _.map(c, offer => (!_.isEmpty(offer?.offers) ? [...offer.offers] : [])),
        c => _.flatten(c),
        c => _.filter(c, offer => offer.isSelect && _.isEmpty(offer.errors)),
        c => _.reduce(c, s => s + 1, 0)
      )(this.offers) > 0
  }

  afterChange = (isRecalculateRemotelyCalculatedServicePrices = true) => {
    this.clearOffers()
    if (isRecalculateRemotelyCalculatedServicePrices) this.recalculateRemotelyCalculatedServicePricesDebounced()
  }

  recalculateRemotelyCalculatedServicePricesDebounced = _.debounce(this.recalculateRemotelyCalculatedServicePrices, 750)

  applyPrepareData = (params = {}) => {
    let { point, user } = params

    if (!point && !user) {
      point = FactoryModel.SettingsModel.CommonModel.point
      user = {
        id: FactoryModel.SettingsModel.ProfileModel.id,
        fullName: FactoryModel.SettingsModel.ProfileModel.fullName
      }
    }
    this.action(() => {
      if (point) {
        this.pointList = [point]
        this.pointId = point.id
      }

      if (point && user) {
        this.userList = [user]
        this.userId = user.id
      } else {
        this.userId = null
      }
    })
  }

  getPointList = async (params = {}) => {
    const { search = '', isReload = true } = params
    if (!isReload && !_.isEmpty(this.pointList)) return

    try {
      this.applyData({ isLoadingPointList: true })
      const pointList = await FactoryProvider.PointProvider.searchMyPoint({ search, isActive: true })
      this.applyData({ pointList })
    } catch (e) {
      notifyError('Ошибка получения точек', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isLoadingPointList: false })
    }
  }

  setPointId = pointId => {
    if (isSafe(pointId) && this.pointId === pointId) return
    this.applyData({
      pointId,
      userList: [],
      userId: null,
      callCenterInfo: null
    })
    this.afterChange()

    Promise.all([
      this.getUserList(),
      this.getServices({ isForce: true }),
      this.getCallCenterInfo()
    ])
  }

  getUserList = async (params = {}) => {
    const { search = '' } = params

    try {
      this.applyData({ isLoadingUserList: true })
      const userList = await FactoryProvider.UserProvider.searchUsers({
        search,
        pointID: this.pointId,
        isActive: true
      })
      this.applyData({ userList })
    } catch (e) {
      notifyError('Ошибка получения пользователей', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isLoadingUserList: false })
    }
  }

  setUserId = userId => {
    this.applyData({ userId })
    this.afterChange()
  }

  setPrepareField = field => value => {
    this.applyData({ [field]: value })
    this.afterChange()
  }

  getCallCenterInfo = async () => {
    if (!this.pointId) return

    try {
      this.applyData({ isLoadingCallCenterInfo: true })
      const data = await FactoryProvider.PickupProvider.getCallCenterInfo({ pointId: this.pointId })
      this.applyData(data)
    } finally {
      this.applyData({ isLoadingCallCenterInfo: false })
    }
  }

  getServices = async (params = {}) => {
    const { isForce = false } = params
    if (!_.isEmpty(this.services) && !isForce) return
    try {
      this.applyData({ isLoadingServices: true })
      const services = await FactoryProvider.PickupProvider.getServices({ pointId: this.pointId })
      this.applyData({ services })
    } finally {
      this.applyData({ isLoadingServices: false })
    }
  }

  getField = (field, isDetails = false) => {
    if (!isDetails) return field
    return `details${_.map(field.split(''), (f, i) => (i === 0 ? f.toUpperCase() : f)).join('')}`
  }

  selectService = action(async (id, isChecked = null) => {
    let serviceIndex = -1
    let groupIndex = -1

    _.each(this.services, (group, index) => {
      serviceIndex = _.findIndex(group.services, s => s.id === id)
      groupIndex = index
      if (serviceIndex !== -1) return false
    })

    if (serviceIndex === -1) return

    const service = this.services[groupIndex].services[serviceIndex]

    service.isSelect = isSafe(isChecked) ? isChecked : !service.isSelect

    if (service.type === 'radio') {
      this.services[groupIndex].services.forEach((s, i) => {
        if (s.id === id || s.type !== 'radio') return
        this.services[groupIndex].services[i].isSelect = false
      })
    }

    if (service.isRemoteCalc) {
      if (service.isSelect) {
        this.action(() => { service.isLoading = true })
        const cost = await this.calculateServiceCost(id)
        if (!cost) this.action(() => { service.isSelect = false })
        this.action(() => { service.price = cost })
        this.action(() => { service.isLoading = false })
      } else {
        this.action(() => { service.price = null })
      }
    }

    this.afterChange(false)

    if (!this.isPreview && !service.isSelect) {
      if ([SERVICE_LIFE, SERVICE_EMPLOYMENT, SERVICE_SMS_BASIC, SERVICE_SMS_PLUS].includes(service.code)) {
        if (service.isReminderClosed) return
        this.action(() => { service.isReminderOpen = true })
        FactoryModel.AnalyticsModel.handleReminderOpen(service.name)
      }
    }
  })

  mapGoods = () => _.map(FactoryModel.QuestionaryModel.OrderModel.orders, goods => (
    { name: goods.orderName, price: Number(goods.cost), count: Number(goods.count), category_id: goods.category, manufacturer: goods.producer }
  ))

  mapPreviewGoods = (cost) => (
    [
      { name: 'from_calculator', price: Number(cost), count: 1 }
    ]
  )

  calculateServiceCost = async (id) => {
    const goods = !this.isPreview ? this.mapGoods() : this.mapPreviewGoods(this.cost)
    if (this.isPreview && Number(this.cost) <= 0) {
      notifyError('Не указана стоимость товаров')
      return null
    }

    return await FactoryProvider.PickupProvider.getServiceCost(id, this.initialPayment, this.term, goods)
  }

  clearSubscription = () => {
    if (this.calculationId && this.subId) {
      FactoryProvider.ActionCableProvider.removeSubscription(this.subId)
      this.subId = null
    }
  }

  calculate = async () => {
    this.clearSubscription()

    const additionalServices = _.flow(
      list => _.reduce(list, (s, group) => [...s, ...group.services], []),
      list => _.filter(list, l => l.isSelect),
      list => _.filter(list, l => (l.isRemoteCalc ? l.price !== 0 : true)),
      list => _.map(list, l => l.id)
    )(this.services)

    const goods = !this.isPreview ? this.mapGoods() : this.mapPreviewGoods(this.cost)
    const birthDate = !this.isPreview ? FactoryModel.QuestionaryModel.MainModel.birthDate : null

    try {
      this.applyData({ isLoadingOffers: true, isGetOffersAfterWait: false, postSentryOffers: [] })
      const prepare = safeObject({
        term: this.term,
        initialPayment: this.initialPayment,
        goods,
        isAvailDiscount: this.isAvailDiscount,
        birthDate,
        additionalServices,
        pointId: !this.isPreview || this.isOwnerChange ? this.pointId : null,
        userId: !this.isPreview || this.isOwnerChange ? this.userId : null
      })

      const { calculationId, offers } = await FactoryProvider.PickupProvider.calculate(prepare)
      this.applyPostOffers({ calculationId, offers, calculationDate: new Date() })

      this.subId = FactoryProvider.ActionCableProvider.addSubscription({
        channel: 'PickupChannel',
        options: {
          _id: this.calculationId
        },
        meta: {
          calculationId: this.calculationId
        }
      })

      this.waitFinishCalculate(calculationId)
    } catch (e) {
      notifyError('Ошибка подбора', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isLoadingOffers: false })
    }
  }

  applyPostOffers = ({ calculationId, calculationDate, offers, initialPayment, term, services }) => {
    this.applyData(safeObject({
      calculationId,
      calculationDate,
      offers,
      originalOffers: [],
      sortOfferBy: '',
      sortOrder: '',
      sortPressCount: 0,
      detailsSortOfferBy: '',
      detailsSortOrder: '',
      detailsSortPressCount: 0,

      initialPayment: isSafe(initialPayment) ? initialPayment : null,
      term: isSafe(term) ? term : null,
      services: isSafe(services) ? services : null,
      isInitOffers: true
    }))
    scrollTo('short:offers')
  }

  waitFinishCalculate = (calcId) => {
    const intervalId = setTimeout(async () => {
      clearInterval(intervalId)
      this.clearSubscription()

      if (this.isFinallyCalculated || calcId !== this.calculationId) return

      try {
        const { offers } = await FactoryProvider.PickupProvider.getMyOffers(this.calculationId)
        this.applyData({ postSentryOffers: offers })
        _.each(offers, offer => {
          this.recalculateOffer({ bankId: offer.bank.id, offer })
        })
        this.applyData({ isGetOffersAfterWait: true })
        this.finishCalculateOffers({ isForceFinisTips: true, isGetOffers: false })
      } catch (e) {
        notifyError('Ошибка получения продуктов', prepareErrorMessage(e))
        throw e
      }
    }, this.CALCULATION_TIMEOUT * 1000)
  }

  applyParams = (params = {}) => {
    const { calculationId, signedProducts } = params
    if (!calculationId) return

    this.applyData({ calculationId })
    this.signedProducts = signedProducts
  }

  initOffers = async () => {
    if (!this.calculationId || !_.isEmpty(this.offers) || this.isInitOffers) return

    try {
      this.applyData({ isLoadingOffers: true })
      const result = await FactoryProvider.PickupProvider.getMyOffers(this.calculationId, true)
      const offers = _.map(result.offers, app => {
        const signedProduct = _.find(this.signedProducts, s => s.bankId === app.bank.id)
        if (!signedProduct) return app

        return {
          ...app,
          offers: _.map(app?.offers, offer => ({
            ...offer,
            isSelect: offer.id === signedProduct.id
          }))
        }
      })

      this.applyPostOffers({
        calculationId: this.calculationId,
        offers,
        initialPayment: result.initialPayment,
        term: result.term,
        services: _.map(this.services, g => ({
          ...g,
          services: _.map(g?.services, s => ({
            ...s,
            isSelect: _.findIndex(result.services, r => r === s.id) !== -1
          }))
        }))
      })

      this.signedProducts = []
    } catch (e) {
      notifyError('Ошибка получения продуктов', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isLoadingOffers: false })
    }
  }

  finishCalculateOfferTipsSum = async (bankId, productId, tipsSum, tipsError) => {
    const newOffers = this.offers.map((offer) => {
      if (offer.bank.id === bankId) {
        return {
          ...offer,
          offers: offer.offers.map((productOffer) => {
            if (productOffer.id === productId) {
              return {
                ...productOffer,
                tipsError,
                tipsSum,
                tipsLoaded: true
              }
            }
            return productOffer
          })
        }
      }
      return offer
    })
    this.applyData({ offers: newOffers })
  }

  recalculateOffer = async ({ bankId, offer }) => {
    const index = _.findIndex(this.offers, currentOffer => currentOffer.bank.id === bankId)
    if (index === -1) return

    const isAllTipsLoaded = _.every(this.offers[index].offers, (currentOffer) => currentOffer.tipsLoaded)

    if (isAllTipsLoaded &&
      (!this.offers[index]?.isProcessGetOffers || this.offers[index]?.isMissOffers)
    ) return

    this.action(() => {
      this.offers[index] = deepMerge({}, offer)
      if (offer.isMissOffers) {
        this.offers[index].isMissOffers = true
        this.offers[index].isProcessGetOffers = false
      } else if (this.offers[index].isProcessGetOffers !== offer.isProcessGetOffers || !isAllTipsLoaded) {
        this.offers[index] = offer
      }
    })

    if (!this.isProcessGetOffers) {
      await this.finishCalculateOffers()
    }
  }

  finishCalculateOffers = async (params = {}) => {
    const { isForceFinisTips = false } = params
    let { isGetOffers = true } = params
    _.each(this.offers, (offer, index) => {
      if (offer.isProcessGetOffers) {
        isGetOffers = true
      }
    })
    if (isGetOffers) {
      await this.checkPostFinishGetOffers()
    }
    this.action(() => {
      _.each(this.offers, (offer, index) => {
        if (offer.isProcessGetOffers) {
          this.offers[index].isMissOffers = true
          this.offers[index].isProcessGetOffers = false
        }

        const isFindSelect = _.find(this.offers[index]?.offers, o => o.isSelect)
        this.offers[index].offers = _.map(this.offers[index].offers, (product, i) => ({
          ...product,
          isSelect: isFindSelect ? product.isSelect : i === 0,
          tipsLoaded: isForceFinisTips || product.tipsLoaded
        }))
      })
      this.originalOffers = deepMerge([], this.offers)
    })
  }

  // TODO Запросить офферы руками, если присутствуют пустые банки или офферы с ошибками
  checkPostFinishGetOffers = async () => {
    // const unSelectedOffers = sentryGetUnselectedPickupOffers(deepMerge([], this.offers))
    // const emptyBanks = sentryGetMissProductsPickupOffers(deepMerge([], this.offers))
    // if (_.isEmpty(unSelectedOffers) && _.isEmpty(emptyBanks)) return

    const { offers } = await FactoryProvider.PickupProvider.getMyOffers(this.calculationId)
    _.each(offers, offer => {
      this.finishCheckPostOffer({
        bankId: offer?.bank?.id,
        offer
      })
    })

    this.applyData({
      isGetOffersAfterWait: true,
      postSentryOffers: offers
    })
  }

  finishCheckPostOffer = action(({ offer, bankId }) => {
    const index = _.findIndex(this.offers, currentOffer => currentOffer.bank.id === bankId)
    if (index === -1) return

    this.offers[index] = deepMerge({}, offer)
    this.offers[index].isProcessGetOffers = false
    this.offers[index].isMissOffers = _.isEmpty(offer?.offers)
    this.offers[index].offers = _.map(this.offers[index].offers, (product, i) => ({
      ...product,
      isSelect: i === 0,
      tipsLoaded: true
    }))
  })

  sortOffers = (offers, sortField, order = 'asc', isDetails = false) => offers.sort((a, b) => {
    const firstIndex = _.findIndex(a.offers, o => o.isSelect)
    const secondIndex = _.findIndex(b.offers, o => o.isSelect)
    const first = !isDetails ? _.get(a, `offers.${firstIndex !== -1 ? firstIndex : 0}.${sortField}`) :
      a[sortField]
    const second = !isDetails ? _.get(b, `offers.${secondIndex !== -1 ? secondIndex : 0}.${sortField}`) :
      b[sortField]
    if (order === 'asc') {
      return first > second ? 1 :
        first < second ? -1 : 0
    }
    return first > second ? -1 :
      first < second ? 1 : 0
  })

  setSortOffer = action((sortField, isDetails = false) => {
    const byKey = this.getField('sortOfferBy', isDetails)
    const orderKey = this.getField('sortOrder', isDetails)
    const offersKey = this.getField('offers', isDetails)
    const originalOffersKey = this.getField('originalOffers', isDetails)
    const sortPressCountKey = this.getField('sortPressCount', isDetails)

    if (this[byKey] === sortField && this[sortPressCountKey] === 2) {
      this[offersKey] = deepMerge([], this[originalOffersKey])
      this[byKey] = ''
      this[orderKey] = ''
      this[sortPressCountKey] = 0
      return
    }

    if (this[byKey] !== sortField) {
      this[sortPressCountKey] = 0
    }

    const order = this[byKey] !== sortField ? 'asc' :
      this[orderKey] === 'asc' ? 'desc' : 'asc'
    this[byKey] = sortField
    this[orderKey] = order

    this[sortPressCountKey] += 1

    const offers = this.sortOffers(toJS(this[offersKey]), sortField, order, isDetails)
    this.applyData({ [offersKey]: offers })
  })

  setIsShowMonthly = action((isDetails = false) => {
    const byKey = this.getField('sortOfferBy', isDetails)
    const orderKey = this.getField('sortOrder', isDetails)
    const showMonthlyKey = this.getField('isShowMonthly', isDetails)
    const offersKey = this.getField('offers', isDetails)
    const originalOffersKey = this.getField('originalOffers', isDetails)
    const sortPressCountKey = this.getField('sortPressCount', isDetails)

    this[showMonthlyKey] = !this[showMonthlyKey]
    this[byKey] = ''
    this[orderKey] = ''
    this[sortPressCountKey] = 0
    this[offersKey] = deepMerge([], this[originalOffersKey])
  })

  setIsShowTipsSum = action((isDetails = false) => {
    const byKey = this.getField('sortOfferBy', isDetails)
    const orderKey = this.getField('sortOrder', isDetails)
    const isShowTipsKeyx = this.getField('isShowTipsSum', isDetails)
    const offersKey = this.getField('offers', isDetails)
    const originalOffersKey = this.getField('originalOffers', isDetails)
    const sortPressCountKey = this.getField('sortPressCount', isDetails)

    this[offersKey] = deepMerge([], this[originalOffersKey])
    this[isShowTipsKeyx] = !this[isShowTipsKeyx]
    this[byKey] = ''
    this[orderKey] = ''
    this[sortPressCountKey] = 0
  })

  showOfferDetails = action(bankID => {
    const index = _.findIndex(this.offers, o => o.bank.id === bankID)
    this.detailsOffers = deepMerge([], this.offers[index].offers)
    this.detailsOriginalOffers = deepMerge([], this.detailsOffers)
    this.currentOfferDetails = deepMerge({}, _.find(this.detailsOffers, offer => offer.isSelect) || this.detailsOffers[0])
    this.currentOfferDetailsIndex = index
    this.detailsIsShowTipsSum = this.isShowTipsSum
    this.detailsIsShowMonthly = this.isShowMonthly
    this.detailsSortPressCount = 0
    this.isShowOfferDetails = true
  })

  closeOfferDetails = action(() => {
    this.applyData({ isShowOfferDetails: false })

    const services = deepMerge([], this.currentOfferDetails.services)
    this.detailsOffers.forEach(f => f.services = services)
    this.offers[this.currentOfferDetailsIndex].offers = deepMerge([], this.detailsOffers)
    this.offers = _.map(this.offers, o => ({ ...o, services }))
    this.originalOffers = deepMerge([], this.offers)

    this.detailsOffers = []
    this.detailsOriginalOffers = []
    this.detailsSortOfferBy = ''
    this.detailsSortOrder = ''
    this.detailsSortPressCount = 0
    this.detailsIsShowTipsSum = this.isShowTipsSum
    this.detailsIsShowMonthly = this.isShowMonthly
    this.currentOfferDetailsIndex = -1
    this.currentOfferDetails = {}

    this.sortOfferBy = ''
    this.sortOrder = ''
  })

  selectOfferDetails = action(index => {
    this.detailsOffers.forEach(offer => offer.isSelect = false)
    this.detailsOffers[index].isSelect = true
    this.closeOfferDetails()
  })

  @computed get isOwnerChange() {
    if (this.isPreview) {
      return this.ownerChangeAccess.has(this.GLOBAL_ACCESS)
    }

    return this.ownerChangeAccess.has(this.QUESTIONARY_ACCESS)
  }

  applyOwnerChangeAccess = action(({ globalAccess, questionaryAccess }) => {
    if (isSafe(globalAccess)) {
      if (globalAccess) {
        this.ownerChangeAccess.add(this.GLOBAL_ACCESS)
      } else {
        this.ownerChangeAccess.delete(this.GLOBAL_ACCESS)
      }
    }
    if (isSafe(questionaryAccess)) {
      if (questionaryAccess) {
        this.ownerChangeAccess.add(this.QUESTIONARY_ACCESS)
      } else {
        this.ownerChangeAccess.delete(this.QUESTIONARY_ACCESS)
      }
    }
  })

  closeReminder = (id) => {
    let serviceIndex = -1
    let groupIndex = -1

    _.each(this.services, (group, index) => {
      serviceIndex = _.findIndex(group.services, s => s.id === id)
      groupIndex = index
      if (serviceIndex !== -1) return false
    })
    const service = this.services[groupIndex].services[serviceIndex]
    this.action(() => { service.isReminderOpen = false })
    this.action(() => { service.isReminderClosed = true })
  }
}

export { PickupModel }
